@font-face {
  font-family: 'Tiempos Text';
  src: url('/fonts/TiemposTextWeb-Regular.woff2') format('woff2'),
       url('/fonts/TiemposTextWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('/fonts/CalibreWeb-Regular.woff2') format('woff2'),
       url('/fonts/CalibreWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('/fonts/CalibreWeb-Bold.woff2') format('woff2'),
       url('/fonts/CalibreWeb-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
body {
  font-family: 'Calibre', 'Arial', sans-serif;
  color: hsl(0, 0%, 0%);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizeLegibility;
}
a:link,
  a:active,
  a:visited,
  a:hover{
  color: hsl(0, 0%, 0%);
  transition: all 0.2s;
}
a:hover{
  background-color: hsl(0, 0%, 65%);
}
a img{
  border: 0;
}
p {
  margin-top: 0
}
p:last-child{
  margin-bottom: 0;
}
@media screen{
  p a{
    font-family: 'Calibre', 'Arial', sans-serif;
    font-size: 1.15em;
    line-height: 1;
  }
}
@media print{
  p a{
    text-decoration: none;
  }
}
p a:hover{
  color: hsl(115, 100%, 100%);
  background-color: transparent;
}
p.lead {
  font-family: 'Tiempos Text', 'Arial', sans-serif;
  max-width: 30em
}
@media         (max-width: 1090px){
  p.lead{
    font-size: 1.8em;
    line-height: 1.45;
  }
}
@media         (min-width: 1091px){
  p.lead{
    font-size: 4.8em;
    line-height: 1.25;
  }
}
.scale--1x {
  font-size: 1.4em}
@media         (max-width: 1090px){
  .scale--1x{
    font-size: 10px;
  }
}
.scale--2x {
  font-size: 1.8em}
@media         (max-width: 1090px){
  .scale--2x{
    font-size: 1.4em;
  }
}
.scale--3x {
  font-size: 2em}
@media         (max-width: 1090px){
  .scale--3x{
    font-size: 1.8em;
  }
}
.scale--4x {
  font-size: 2.8em}
@media         (max-width: 1090px){
  .scale--4x{
    font-size: 2em;
  }
}
.scale--5x {
  font-size: 4.8em}
@media         (max-width: 1090px){
  .scale--5x{
    font-size: 2.8em;
  }
}
.scale--6x {
  font-size: 6em}
@media         (max-width: 1090px){
  .scale--6x{
    font-size: 4.8em;
  }
}
@media      (max-width: 500px){
  .scale--6x{
    font-size: 2.8em;
  }
}
.display-font-family { font-family: 'Tiempos Text', 'Arial', sans-serif }
.padding-bottom--half { padding-bottom: 0.75em }
.nowrap { white-space: nowrap }
.columns:after{
  content: '';
  display: table;
  clear: both;
}
.columns > *{
  margin: 0 0 2rem 0;
  padding: 0;

  /* &:last-child { margin-bottom: 0 } */
}
@media         (min-width: 1091px){
  .columns > *{
    margin-bottom: 5rem;
  }
}
@media         (min-width: 1091px){
  .columns > *{
    width: calc(99.9% * 1/2 - (48px - 48px * 1/2));
  }
  .columns > *:nth-child(1n){
    float: left;
    margin-right: 48px;
    clear: none;
  }
  .columns > *:last-child{
    margin-right: 0;
  }
  .columns > *:nth-child(2n){
    margin-right: 0;
    float: right;
  }
  .columns > *:nth-child(2n + 1){
    clear: both;
  }
  .columns p{
    margin-bottom: 1.55em;
  }
  .columns{

    /* same as --text-line-height, baseline grid */
  }
}
@media    (max-width: 1460px){
  .hide-max-standard{
    position: absolute;
    top: -999em;
    left: -999em;
  }
}
.Artwork *{
  pointer-events: none;
}
.CallToAction {
  box-sizing: border-box;
  position: relative;
  min-height: 5em;
  font-size: 1rem;
  padding: 1.8em 1.8em 1.8em 8.6em;
  background-color: hsl(0, 0%, 0%);
  color: hsl(0, 0%, 71%);
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1)
}
.CallToAction__artwork{
  position: absolute;
  top: 1.8em;
  left: 1.8em;
}
.CallToAction .Artwork{
  width: 5em;
  height: 5em;
  color: currentColor;
}
.CallToAction p{
  font-size: 1.8em;
}
@media         (max-width: 1090px){
  .CallToAction p{
    font-size: 1.4em;
  }
}
.CallToAction p{
  margin: 0 0 0.2em 0;
  color: currentColor;
  font-family: 'Tiempos Text', 'Arial', sans-serif;
  line-height: 1.2;
}
.CallToAction p:last-child{
  font-size: 1em;
}
.CallToAction p{

  /* link is wrapped in a paragraph coming from markdown */
}
.CallToAction a{
  display: inline-block;
  border-bottom: 3px solid currentColor;
  font-size: 2em;
  line-height: 1.2;
  font-family: 'Calibre', 'Arial', sans-serif;
  font-weight: 600;
  color: currentColor;
  text-decoration: none;
  transition: none;
}
.CallToAction a:hover{
  color: white !important;
  background-color: transparent;
}
.CallToAction--contact{
  background-color: hsl(147, 59%, 67%);
  color: hsl(0, 0%, 0%);
}
.CallToAction--contact:hover{}
@media print{
  .CallToAction{
    display: none;
  }
}
.CallToAction.Scenario__download {
  padding-top: 1em;
  padding-right: 1em;
  padding-bottom: 1em
}
.CallToAction.Scenario__download p{
  margin-bottom: 1em;
  font-family: 'Calibre', 'Arial', sans-serif;
}
.CallToAction.Scenario__download .CallToAction__artwork{
  background-color: currentColor;
}
.CallToAction.Scenario__download .Artwork{
  color: black;
}
.Download {
  box-sizing: border-box;
  position: relative;
  padding: 1.8em 1.8em 1.8em 8.6em;
  background-color: hsl(0, 0%, 0%)
}
.Download .Artwork{
  position: absolute;
  top: 1.8em;
  left: 1.8em;
  width: 5em;
  height: 5em;
  background-color: hsl(0, 0%, 71%);
  color: hsl(0, 0%, 0%);
}
.Download p{
  color: hsl(0, 0%, 71%);
  font-family: 'Calibre', 'Arial', sans-serif;
  font-size: 1.8em;
}
@media         (max-width: 1090px){
  .Download p{
    font-size: 1.4em;
  }
}
.Download p{
  line-height: 1.2;
}
.Download a{
  display: inline-block;
  border-bottom: 3px solid hsl(0, 0%, 71%);
  padding-bottom: 0.2em;
  font-weight: 600;
  color: hsl(0, 0%, 71%);
  text-decoration: none;
}
@media print{
  .Download{
    display: none;
  }
}
.FixedHeader {
  top: 0;
  right: 0;
  left: 0;
  line-height: 0
}
.FixedHeader__content{
  max-width: 1460px;
  box-sizing: border-box;
  margin: 0 auto;
}
.FixedHeader__content{
  padding-bottom: 3rem;
}
@media         (min-width: 1091px){
  .FixedHeader__content{
    padding-bottom: 5rem;
  }
}
.FixedHeader--fixed .FixedHeader__content{
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media         (min-width: 1091px){
  .FixedHeader--fixed .FixedHeader__content{
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.FixedHeader p{
  line-height: 1.55;
}
.Footer {
  clear: both;
  background-color: hsl(0, 0%, 0%);
  color: hsl(0, 0%, 71%)
}
.Footer p{
  margin: 0 0 0.8em 0;
  font-family: 'Calibre', 'Arial', sans-serif;
  font-size: 2.2em;
  line-height: 1.18;
}
.Footer p a{
  color: hsl(0, 0%, 71%);
  margin: 0 1em 0 0;
  font-size: .65em;
}
.Footer__logo{
  display: inline-block;
  margin-right: 2em;
}
@media         (max-width: 1090px){
  .Footer__logo{
    margin-top: 2em;
  }
}
@media print{
  .Footer__logo,
    .Footer__legal{
    display: none;
  }
}
.Footer__logo:hover{
  background-color: transparent;
  opacity: 0.8;
}
.Footer__content{
  max-width: 1460px;
  box-sizing: border-box;
  margin: 0 auto;
  padding-top: 1.6em;
}
@media         (max-width: 1090px){
  .Footer__content{
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .Footer__content div:nth-child(2){
    margin-top: 4em;
    margin-bottom: 4em;
  }
  .Footer__content__logo{
    border: 1px solid red;
  }
}
@media         (min-width: 1091px){
  .Footer__content{
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
.FooterNav:after{
  content: '';
  display: table;
  clear: both;
}
.FooterNav{
  display: block;
  clear: both;
  box-sizing: border-box;
}
.FooterNav div.scenario-mobility a:hover{
  background-color: hsl(44, 99%, 60%);
}
.FooterNav div.scenario-sustainability a:hover{
  background-color: rgb(150, 215,  240);
}
.FooterNav div.scenario-jobs-and-the-economy a:hover{
  background-color: hsl(68, 100%, 45%);
}
.FooterNav div.scenario-urban-transformation a{
  background-color: hsl(223, 100%, 79%);
}
.FooterNav div{
  background-color: transparent;
}
.FooterNav__heading{
  margin: 0;
  font-size: 1rem;
}
.FooterNav__heading span{
  font-size: 2em;
}
@media         (max-width: 1090px){
  .FooterNav__heading span{
    font-size: 1.8em;
  }
}
.FooterNav__heading span{
  line-height: 1.4em;
}
.FooterNav > *{
  margin: 0 0 2rem 0;
  padding: 0;

  /* &:last-child { margin-bottom: 0 } */
}
@media         (min-width: 1091px){
  .FooterNav > *{
    margin-bottom: 5rem;
  }
}
.FooterNav a{
  font-size: 2em;
}
@media         (max-width: 1090px){
  .FooterNav a{
    font-size: 1.8em;
  }
}
.FooterNav a:hover,
    .FooterNav a:active,
    .FooterNav a:visited{
  color: currentColor;
}
.FooterNav a,
    .FooterNav a > *{
  cursor: pointer;
  border: 0;
  border: 0;
  color: currentColor;
  text-decoration: none;
}
.FooterNav a{
  box-sizing: border-box;
  padding: 0.55em;
  font-weight: 600;
  line-height: 1;
  min-height: 2.8em;
  font-size: 2em;
}
@media         (max-width: 1090px){
  .FooterNav a{
    font-size: 1.8em;
  }
}
.FooterNav a{
  line-height: 1.4em;
  display: block;
  margin: 0;
}
@media         (min-width: 1091px){
  .FooterNav > *{
    width: calc(99.9% * 1/4 - (5rem - 5rem * 1/4));
  }
  .FooterNav > *:nth-child(1n){
    float: left;
    margin-right: 5rem;
    clear: none;
  }
  .FooterNav > *:last-child{
    margin-right: 0;
  }
  .FooterNav > *:nth-child(4n){
    margin-right: 0;
    float: right;
  }
  .FooterNav > *:nth-child(4n + 1){
    clear: both;
  }
}
@media print{
  .FooterNav{
    display: none;
  }
}
.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: black
}
.Header__logo{
  float: left;
  margin-top: 1em;
}
.Header__logo img{
  width: auto;
  height: 40px;
}
.Header__logo:hover{
  background-color: transparent;
  opacity: 0.8;
}
.Header__logo + .Header__logo{
  margin-left: 2em;
}
@media         (min-width: 1091px){
  .Header__logo img{
    height: 60px;
  }
}
.Header__content {
  box-sizing: border-box;
  max-width: 1460px;
  height: 64px;
  margin: 0 auto;
  padding-right: 1rem;
  padding-left: 1rem
}
.Header__content:after{
  content: '';
  display: table;
  clear: both;
}
@media         (min-width: 1091px){
  .Header__content{
    height: 75px;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
.Waypoint--header {
  display: block;
  height: 64px
}
@media         (min-width: 1091px){
  .Waypoint--header{
    height: 75px;
  }
}
.Intro {
  margin: 0 0 2rem 0;
  padding: 0

    /* &:last-child { margin-bottom: 0 } */
}
@media         (min-width: 1091px){
  .Intro{
    margin-bottom: 5rem;
  }
}
.Intro{
  background-color: hsl(115, 100%, 100%);
  padding: 2em 2em 0 2em;
}
@media         (min-width: 1091px){
  .Intro__title,
    .Intro__text{
    width: calc(99.9% * 2/4 - (48px - 48px * 2/4));
  }
  .Intro__title:nth-child(1n),
    .Intro__text:nth-child(1n){
    float: left;
    margin-right: 48px;
    clear: none;
  }
  .Intro__title:last-child,
    .Intro__text:last-child{
    margin-right: 0;
  }
  .Intro__title:nth-child(4n),
    .Intro__text:nth-child(4n){
    margin-right: 0;
    float: right;
  }
  .Intro__title:nth-child(4n + 1),
    .Intro__text:nth-child(4n + 1){
    clear: both;
  }
}
.Intro > *:only-child,
  .Intro__title{
  font-size: 6em;
}
@media         (max-width: 1090px){
  .Intro > *:only-child,
  .Intro__title{
    font-size: 4.8em;
  }
}
@media      (max-width: 500px){
  .Intro > *:only-child,
  .Intro__title{
    font-size: 2.8em;
  }
}
.Intro > *:only-child,
  .Intro__title{
  margin: 0;
  padding: 0;
  line-height: 1;
  margin-bottom: 0.5em;
}
.Intro__text:last-child{
  font-size: 1.8em;
}
@media         (max-width: 1090px){
  .Intro__text:last-child{
    font-size: 1.4em;
  }
}
.Intro__text:last-child{
  margin-bottom: 1em;
  font-family: 'Tiempos Text', 'Arial', sans-serif;
  line-height: 1.55;
  letter-spacing: 0.01em;
  font-size: 2em;
}
@media         (max-width: 1090px){
  .Intro__text:last-child{
    font-size: 1.8em;
  }
}
.Intro__text:last-child{
  margin-bottom: 2em;
}
.Intro a:hover{
  color: hsl(0, 0%, 65%);
}
.Intro:after{
  content: '';
  display: table;
  clear: both;
}
.Layout--nav-sticky .Nav { position: fixed }
.Nav {
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100
}
.Nav:after{
  content: '';
  display: table;
  clear: both;
}
.Nav a{
  text-decoration: none;
}
.Nav__content{
  background-color: hsl(115, 100%, 100%);
}
.Nav__content:after{
  content: '';
  display: table;
  clear: both;
}
.Nav__home{
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-size: 1em;
}
.Nav__home-link{
  display: block;
  box-sizing: border-box;
  padding: 0 0.5em;
  font-size: 2.8em;
  line-height: 1;
}
.Nav__home-link:hover{
  background-color: transparent;
}
.Nav--open .Nav__sections,
  .Nav--open .Nav__section-menu a{
  display: block;
}
.Nav__toggle{
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 42px;
  height: 42px;
  color: hsl(115, 100%, 100%);
  z-index: 1;
}
@media         (max-width: 1090px){
  .Nav{
    border-bottom: 4px solid hsl(0, 0%, 0%);
  }
  .Nav__home{
    display: flex;
    align-items: center;
    height: calc(4em + 8px);
  }
  .Nav__home-link{
    display: block;
    font-size: 2em;
  }
}
@media         (max-width: 1090px) and         (max-width: 1090px){
  .Nav__home-link{
    font-size: 1.8em;
  }
}
@media         (max-width: 1090px){
  .Nav__home-link{
    padding: 0.25em 1rem;
    line-height: 1;
  }
  .Nav__home-link:hover{
    background-color: transparent;
  }
  .Nav__home-link span{
    display: block;
  }
  .Nav__home-link{

    /* force wrap title */
  }
  .Nav__toggle{
    top: 4px;
    right: 4px;
    width: 4em !important;
    height: 4em !important;
    color: hsl(115, 100%, 100%);
    background-color: hsl(0, 0%, 0%);
  }
}
@media         (min-width: 1091px){
  .Nav{
    margin: 0 0 2rem 0;
    padding: 0;

    /* &:last-child { margin-bottom: 0 } */
  }
}
@media         (min-width: 1091px) and         (min-width: 1091px){
  .Nav{
    margin-bottom: 5rem;
  }
}
@media         (min-width: 1091px){
  .Nav{
    top: 3rem;
    left: 0;
    right: 0;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .Nav__content{
    border-bottom: 2px solid hsl(0, 0%, 0%);
    border-right: 2px solid hsl(0, 0%, 0%);
    display: block;
    min-height: 50px;
    position: relative;
    max-width: calc(1460px - 10rem);
    margin: 0 auto;
  }
  .Nav__home{
    position: absolute;
    top: 0;
    left: 0;
  }
  .Nav__home-link{
    line-height: 50px;
  }
}
@media print{
  .Nav{
    display: none;
  }
}
.Nav__section-menu {
  position: relative;
  box-sizing: border-box;
  padding: 4px;
  background-color: transparent !important
}
.Nav__section-menu a{
  font-size: 2em;
}
@media         (max-width: 1090px){
  .Nav__section-menu a{
    font-size: 1.8em;
  }
}
.Nav__section-menu a:hover,
    .Nav__section-menu a:active,
    .Nav__section-menu a:visited{
  color: currentColor;
}
.Nav__section-menu a,
    .Nav__section-menu a > *{
  cursor: pointer;
  border: 0;
  border: 0;
  color: currentColor;
  text-decoration: none;
}
.Nav__section-menu a{
  box-sizing: border-box;
  padding: 0.55em;
  font-weight: 600;
  line-height: 1;
  display: none;
  position: relative;
}
.Nav__section-menu a:first-child{
  display: block;
}
.Nav__section-menu a + a{
  margin-top: 4px;
}
.Nav__section-menu a:hover{
  color: hsl(0, 0%, 0%) !important;
  background-color: transparent !important;
}
@media         (max-width: 1090px){
  .Nav__section-menu{
    padding-top: 0;
  }
}
@media         (min-width: 1091px){
  .Nav__section-menu{
    width: 30%;
    float: right;
  }
}
.Placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Calibre', 'Arial', sans-serif;
  font-size: 2em;
  font-weight: 600;
  color: hsl(115, 100%, 100%);
  line-height: 1;
}
.Slideshow:after{
  content: '';
  display: table;
  clear: both;
}
.Slideshow{
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  padding-top: calc(1.7em + 4rem);
}
.Slideshow__header{
  margin: 0 !important;

  /* override Scenario__body */
  color: hsl(115, 100%, 100%);
}
.Slideshow__nav{
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  text-align: center;
}
@media screen{
  .Slideshow__content,
    .Slideshow__item{
    float: left;
  }
  .Slideshow__content{
    width: 100%;
  }
  .Slideshow__images{
    display: flex;
    float: left;
  }
}
@media print{
  .Slideshow{
    margin-top: 0 !important;

    /* override paragraph adjacent sibling */
  }
  .Slideshow__nav,
    .Slideshow__hint,
    .Slideshow__header{
    display: none !important;

    /* override Scenario__body heading styles */
  }
  .Slideshow{

    /* override inline JS styles */
  }
  .Slideshow__content{
    width: auto !important;
    padding-right: 0 !important;
  }
  .Slideshow__item,
    .Slideshow__item-intro{
    content: '';
    display: table;
    clear: both;
    width: 100% !important;
    margin-bottom: 5rem !important;
    margin-right: 0 !important;
  }
  .Slideshow__item:nth-last-child(2),.Slideshow__item-intro:nth-last-child(2){
    margin-bottom: 0 !important;
  }
  .Slideshow__item:last-child,.Slideshow__item-intro:last-child{
    margin-bottom: 0 !important;
  }
  .Slideshow__item-image,
    .Slideshow__item-caption{
    width: 50%;
    float: left;
  }
  .Slideshow__item-image img{
    width: 100%;
    height: auto;
  }
  .Slideshow__item-caption{
    box-sizing: border-box;
    padding-left: 2em;
    margin-top: 0 !important;
  }
}
.Slideshow__nav div{
  display: inline-block;
}
.Slideshow__nav-item{
  cursor: pointer;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  outline: none;
  padding: 0;
  background: transparent;
  font-size: 1em;
  width: 2.8em;
  height: 1.7em;
  margin: 0 0.6em;
  background-color: hsl(0, 0%, 0%);
  text-indent: -999em;
}
.Slideshow__nav-item--active{
  background-color: hsl(0, 0%, 96%);
}
.Slideshow__item-intro{
  position: relative;
  box-sizing: border-box;
  text-align: left;
}
@media screen{
  .Slideshow__item-intro{
    float: left;
  }
}
@media         (max-width: 1090px){
  .Slideshow__item-intro{
    padding-right: 5em;
  }
}
.Slideshow__item-intro *{
  margin-top: 0;
}
.Slideshow__item-intro p,
  .Slideshow__item-caption{
  font-family: 'Calibre', 'Arial', sans-serif;
  font-size: 2em;
}
@media         (max-width: 1090px){
  .Slideshow__item-intro p,
  .Slideshow__item-caption{
    font-size: 1.8em;
  }
}
.Slideshow__item-intro p,
  .Slideshow__item-caption{
  line-height: 1.2;
  text-align: left;
}
.Slideshow__item-intro p a,.Slideshow__item-caption a{
  font-size: 1em;
  text-decoration: none;
}
.Slideshow__hint{
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1.4em;
}
.Slideshow__viewer{
  display: block;
  width: 100%;
  overflow: hidden;
}
@media    (min-width: 1461px){
  .Slideshow__viewer{
    width: 100vw;
  }
}
.Slideshow__item{
  margin: 0;
}
.Slideshow__restart{
  cursor: pointer;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  outline: none;
  padding: 0;
  background: transparent;
  font-size: 1em;
  display: block;
  position: relative;
}
.Slideshow__restart-trigger{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Slideshow__restart img{
  opacity: 0;

  /* render to set dynamic aspect ratio-driven height */
}
@media         (max-width: 1090px){
  .Slideshow__restart{
    width: 11vw;
  }
}
@media         (min-width: 1091px){
  .Slideshow__restart{
    width: 13em;
  }
}
.Slideshow__restart .Artwork{
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.Slideshow__restart .Artwork:hover{
  color: hsl(115, 100%, 100%);
}
@media         (max-width: 1090px){
  .Slideshow__restart .Artwork{
    width: 11vw;
    height: 11vw;
  }
}
@media         (min-width: 1091px){
  .Slideshow__restart .Artwork{
    width: 13em;
    height: 13em;
  }
}
@media print{
  .Slideshow__restart{
    display: none;
  }
}
.Slideshow__item-image{
  color: hsl(115, 100%, 100%);
  line-height: 0;
}
.Slideshow--animating .Scenario__item-image{
  cursor: default;
}
.Slideshow__item-image--previous{
  cursor: url('/cursors/pagination-back.svg') 60 50.5, pointer;
}
.Slideshow__item-image--next{
  cursor: url('/cursors/pagination-next.svg') 60 50.5, pointer;
}
.Slideshow__item--active .Slideshow__item-image{
  cursor: default;
  position: relative;
  z-index: 1;
}
.Slideshow__item-image img{
  width: 100%;
  height: auto;
}
.Slideshow__item-caption{
  margin-top: 1em;
}
.Slideshow__pagination {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}
.Slideshow__pagination > div{
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
.Slideshow__pagination > div:before{
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.Slideshow__pagination-back{
  right: 50%;
  left: calc(-5rem + -9.5em);
}
.Slideshow__pagination-back:hover{
  cursor: url('/cursors/pagination-back.svg') 60 50.5, pointer;
}
.Slideshow__pagination-back:hover .Artwork{
  opacity: 1;
}
.Slideshow__pagination-next{
  left: 50%;
  right: calc(-5rem + -9.5em);
  text-align: right;
}
.Slideshow__pagination-next:hover{
  cursor: url('/cursors/pagination-next.svg') 60 50.5, pointer;
}
.Slideshow__pagination-next:hover .Artwork{
  opacity: 1;
}
.Slideshow__pagination-back:only-child{
  right: 0%;
}
.Slideshow__pagination-next:only-child{
  left: 0%;
}
.Slideshow__pagination .Artwork{
  width: 12em;
  height: 10em;
  display: inline-block;
  opacity: 0;
  vertical-align: middle;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}
.Layout {
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  z-index: 50; /* lift above header */
  background-color: hsl(0, 0%, 65%);
  transition: 0.5s background-color
}
.Layout:before{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  content: '';
  position: fixed;
  background-color: hsla(0, 0%, 0%, .0);
  z-index: 100;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
}
.Layout--nav-open:before{
  pointer-events: initial;
  opacity: 1;
}
.Layout--preparing{
  background-color: rgb(150, 215,  240);
}
.Layout--piloting{
  background-color: hsl(44, 99%, 60%);
}
.Layout--watching{
  background-color: var(--watching-color);
}
.Layout__background{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
  opacity: .30;
  display: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}
.Layout--background-image .Layout__background{
  display: block;
}
.Layout--background-image .Layout__content,
    .Layout--background-image .Layout__footer{
  position: relative;
  z-index: 50;
}
.Layout__content{
  width: 100%;
  min-height: calc(100vh - 12rem - 157px);
  float: left;
}
@media         (min-width: 1091px){
  .Layout__content{
    min-height: calc(100vh - 20rem - 155px);
  }
}
.Layout__header,
  .Layout__main,
  .Layout__footer{
  position: relative;
  z-index: 50;
}
.Layout__main{
  margin: 0 0 2rem 0;
  padding: 0;

  /* &:last-child { margin-bottom: 0 } */
}
@media         (min-width: 1091px){
  .Layout__main{
    margin-bottom: 5rem;
  }
}
.Layout__main{
  max-width: 1460px;
  box-sizing: border-box;
  margin: 0 auto;
}
.Layout__main:after{
  content: '';
  display: table;
  clear: both;
}
.Layout__main{
  display: block;
  padding-top: calc(52px + 3rem);
  padding-right: 1rem;
  padding-left: 1rem;
  position: relative;
  z-index: 50;
}
@media         (min-width: 1091px){
  .Layout__main{
    padding-top: calc(8rem + 50px);
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
@media print{
  .Layout__main{
    padding-top: 0;
  }
}
.Layout__overlay{
  overflow: hidden;
  margin: 0 -1rem;
  padding: 1rem;
  border-top: 2px solid hsl(0, 0%, 0%);
  position: relative;
  z-index: 51;
}
.Layout__overlay .Layout__main{
  padding-top: 1rem;
}
@media         (min-width: 1091px){
  .Layout__overlay{
    margin: 0 -5rem;
    padding: 5rem;
  }
}
@media print{
  .Layout__overlay{
    border-top: 0;
  }
}
.Layout:after{
  content: '';
  display: table;
  clear: both;
}
.Layout[data-route="/"] {
  background-color: hsl(0, 0%, 71%)
}
.Layout[data-route="/"] .Layout__overlay{
  background-color: hsl(0, 0%, 71%);
}
.Scenarios:after{
  content: '';
  display: table;
  clear: both;
}
.Scenariosdiv.FixedHeader__content{
  color: red;
}
.Scenarios{
  overflow: hidden;
}
.Scenarios__item:hover,
    .Scenarios__item:active,
    .Scenarios__item:visited{
  color: currentColor;
}
.Scenarios__item,
    .Scenarios__item > *{
  cursor: pointer;
  border: 0;
  border: 0;
  color: currentColor;
  text-decoration: none;
}
.Scenarios__item{
  margin-bottom: 1rem;
  box-sizing: border-box;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.Scenarios__item img{
  width: 100%;
  height: auto;
}
.Scenarios__item-subtitle{
  margin: 0 0 0.5em 0;
  line-height: 1;
}
.Scenarios__item-title{
  margin: 0 0 0.65em 0;
  line-height: 1;
}
.Scenarios__item:hover{
  color: hsl(115, 100%, 100%);
}
@media         (max-width: 1090px){
  .Scenarios p{
    font-size: 1.8em;
  }
  .Scenarios__item{
    width: 100%;
    float: left;
    padding: 1.5rem;
  }
  .Scenarios__item-subtitle{
    font-size: 1.8em;
  }
  .Scenarios__item-title{
    font-size: 2.8em;
  }
}
@media         (min-width: 1091px){
  .Scenarios__grid{
    float: left;
    margin-bottom: 5rem;
  }
  .Scenarios__item{
    width: calc(99.9% * 1/2 - (48px - 48px * 1/2));
    margin-bottom: 5rem;
    padding: 2rem 2.5rem;
  }
  .Scenarios__item:nth-child(1n){
    float: left;
    margin-right: 48px;
    clear: none;
  }
  .Scenarios__item:last-child{
    margin-right: 0;
  }
  .Scenarios__item:nth-child(2n){
    margin-right: 0;
    float: right;
  }
  .Scenarios__item:nth-child(2n + 1){
    clear: both;
  }
  .Scenarios__item-subtitle{
    font-size: 2.8em;
  }
  .Scenarios__item-title{
    font-size: 6em;
  }
}
.Layout[data-route*="/mobility"] {
  background-color: hsl(44, 99%, 60%)
}
.Layout[data-route*="/mobility"] .Layout__overlay, .Layout[data-route*="/mobility"] .Scenario__slideshow, .Layout[data-route*="/mobility"] .Scenario__image{
  background-color: hsl(44, 99%, 60%);
}
.Layout[data-route*="/sustainability"] {
  background-color: rgb(150, 215,  240)
}
.Layout[data-route*="/sustainability"] .Layout__overlay, .Layout[data-route*="/sustainability"] .Scenario__slideshow, .Layout[data-route*="/sustainability"] .Scenario__image{
  background-color: rgb(150, 215,  240);
}
.Layout[data-route*="/jobs-and-the-economy"] {
  background-color: hsl(68, 100%, 45%)
}
.Layout[data-route*="/jobs-and-the-economy"] .Layout__overlay, .Layout[data-route*="/jobs-and-the-economy"] .Scenario__slideshow, .Layout[data-route*="/jobs-and-the-economy"] .Scenario__image{
  background-color: hsl(68, 100%, 45%);
}
.Layout[data-route*="/urban-transformation"] {
  background-color: hsl(223, 100%, 79%)
}
.Layout[data-route*="/urban-transformation"] .Layout__overlay, .Layout[data-route*="/urban-transformation"] .Scenario__slideshow, .Layout[data-route*="/urban-transformation"] .Scenario__image{
  background-color: hsl(223, 100%, 79%);
}
.Layout[data-route*="/urban-transformation"] .Scenario h2{

  /* background-color: black;
    color: var(--purple-color);
    padding: 0.2em 0.5em; */
}
.Scenario:after{
  content: '';
  display: table;
  clear: both;
}
.Scenario__poster{
  width: 100%;
  height: auto;
}
.Scenario__nav{
  border-top: 2px solid hsl(0, 0%, 0%);
  padding-top: 2.5rem;
}
.Scenario__nav a:hover{
  color: white;
}
.Scenario .Scenario__lead,
  .Scenario .Scenario__subtitle{
  display: none;
}
@media      (max-width: 500px){
  .Scenario .Scenario__lead,
    .Scenario .Scenario__subtitle{
    font-family: 'Calibre', 'Arial', sans-serif;
    font-size: 2.8em;
  }
}
@media      (max-width: 500px) and         (max-width: 1090px){
  .Scenario .Scenario__lead,
    .Scenario .Scenario__subtitle{
    font-size: 2em;
  }
}
@media      (max-width: 500px){
  .Scenario .Scenario__lead,
    .Scenario .Scenario__subtitle{
    font-weight: 600;
    display: block;
    line-height: 1.2;
  }
  .Scenario .Scenario__poster [id*="-lead"],
    .Scenario .Scenario__poster [id*="-subtitle"]{
    opacity: 0;
  }
}
.Scenario__body:after{
  content: '';
  display: table;
  clear: both;
}
.Scenario__body{
  margin: 0 0 2rem 0;
  padding: 0;

  /* &:last-child { margin-bottom: 0 } */
}
@media         (min-width: 1091px){
  .Scenario__body{
    margin-bottom: 5rem;
  }
}
.Scenario__body > div > p{
  font-size: 1.8em;
}
@media         (max-width: 1090px){
  .Scenario__body > div > p{
    font-size: 1.4em;
  }
}
.Scenario__body > div > p{
  margin-bottom: 1em;
  font-family: 'Tiempos Text', 'Arial', sans-serif;
  line-height: 1.55;
  letter-spacing: 0.01em;
  display: inline-block;
  word-spacing: 0.1em;
}
.Scenario__body > div > p + *{
  margin-top: 3.6em;
}
.Scenario__body > div > p + * + *{
  margin-top: 3.6em;
}
.Scenario__body > div > p + p{
  margin-top: 0;
}
.Scenario__body > div > p + * + p{
  margin-top: 0;
}
@media         (max-width: 1090px){
  .Scenario__body > div > p{
    font-size: 2em;
  }
}
@media         (max-width: 1090px) and         (max-width: 1090px){
  .Scenario__body > div > p{
    font-size: 1.8em;
  }
}
.Scenario__body .Scenario__image + p,
  .Scenario__body .pull-left + p,
  .Scenario__body .pull-right + p{
  margin-top: 0 !important;

  /* override adjacent selector above */
}
.Scenario__body .pull-left,
  .Scenario__body .pull-right{
  width: calc(99.9% * 2/4 - (5rem - 5rem * 2/4));
  position: absolute !important;
  float: none !important;
  margin-top: 0 !important;

  /* override adjacent selector above */
}
.Scenario__body .pull-left:nth-child(1n),
  .Scenario__body .pull-right:nth-child(1n){

  /* override adjacent selector above */
  float: left;
  margin-right: 5rem;
  clear: none;
}
.Scenario__body .pull-left:last-child,
  .Scenario__body .pull-right:last-child{

  /* override adjacent selector above */
  margin-right: 0;
}
.Scenario__body .pull-left:nth-child(4n),
  .Scenario__body .pull-right:nth-child(4n){

  /* override adjacent selector above */
  margin-right: 0;
  float: right;
}
.Scenario__body .pull-left:nth-child(4n + 1),
  .Scenario__body .pull-right:nth-child(4n + 1){

  /* override adjacent selector above */
  clear: both;
}
.Scenario__body .pull-left{
  position: relative;
  left: calc(99.9% * -2/4 - (48px - 48px * -2/4) + 48px);
}
.Scenario__body .pull-right{
  position: relative;
  left: calc(99.9% * 4/4 - (48px - 48px * 4/4) + 48px);
}
.Scenario__body .pull-left p,
  .Scenario__body .pull-right p{
  color: hsl(0, 0%, 0%);
  font-family: 'Calibre', 'Arial', sans-serif;
  font-size: 2em;
}
@media         (max-width: 1090px){
  .Scenario__body .pull-left p,
  .Scenario__body .pull-right p{
    font-size: 1.8em;
  }
}
.Scenario__body .pull-left p,
  .Scenario__body .pull-right p{
  line-height: 1.2;
}
.Scenario__body{

  /* content blocks */
}
.Scenario__body h1,
  .Scenario__body h2,
  .Scenario__body h3{
  display: block;
  margin: 0 0 1.2em 0;
  font-size: 1rem;
  font-family: 'Calibre', 'Arial', sans-serif;
  line-height: 1;
  text-align: left;
}
.Scenario__body h1 span,
  .Scenario__body h2 span{
  display: block;
  box-sizing: border-box;
  padding: 0.2em 0em;
  font-size: 4em;
}
@media screen{
  .Scenario__body h3{
    font-size: 2.8em;
  }
}
@media screen and         (max-width: 1090px){
  .Scenario__body h3{
    font-size: 2em;
  }
}
@media print{
  .Scenario__body h2,
    .Scenario__body .Slideshow{
    page-break-before: always;
  }
  .Scenario__body h3 span{
    display: block;
    box-sizing: border-box;
    padding: 0.2em 0em;
    font-size: 4em;
  }
}
.Scenario__body blockquote{
  margin: 0;
  box-sizing: border-box;
  padding: 1.25em 2.5em;
}
.Scenario__body .pull-left blockquote p,
  .Scenario__body .pull-right blockquote p{
  color: hsl(0, 0%, 0%);
  font-size: 2.8em;
}
@media         (max-width: 1090px){
  .Scenario__body .pull-left blockquote p,
  .Scenario__body .pull-right blockquote p{
    font-size: 2em;
  }
}
.Scenario__body .pull-left blockquote p,
  .Scenario__body .pull-right blockquote p{
  font-family: 'Calibre', 'Arial', sans-serif;
  line-height: 1.1;
}
.Scenario__body .pull-left blockquote p:first-child,.Scenario__body .pull-right blockquote p:first-child{
  margin-bottom: 7em;
}
.Scenario__body .pull-left blockquote p:last-child,.Scenario__body .pull-right blockquote p:last-child{
  font-size: 2em;
}
@media         (max-width: 1090px){
  .Scenario__body .pull-left blockquote p:last-child,.Scenario__body .pull-right blockquote p:last-child{
    font-size: 1.8em;
  }
}
.Scenario__body .pull-left blockquote p:last-child,.Scenario__body .pull-right blockquote p:last-child{
  line-height: 1.2;
}
.Scenario__body .pull-left blockquote p:last-child strong,.Scenario__body .pull-right blockquote p:last-child strong{
  display: block;
}
.Scenario__body .Scenario__image{
  display: block;
  position: relative;
  z-index: 2;
  margin: 0 0 1.8em 0 !important;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding: 2rem 0;
  line-height: 0;
}
.Scenario__body .Scenario__image-content{
  width: 100%;
  height: auto;
}
.Scenario__body .Scenario__image-caption p{
  font-size: 2em;
}
@media         (max-width: 1090px){
  .Scenario__body .Scenario__image-caption p{
    font-size: 1.8em;
  }
}
.Scenario__body .Scenario__image-caption p{
  color: hsl(0, 0%, 0%);
  font-family: 'Calibre', 'Arial', sans-serif;
  line-height: 1.2;
  text-align: left;
}
@media         (max-width: 1090px){
  .Scenario__body .Scenario__image-caption p{
    font-size: 2em;
  }
}
@media         (max-width: 1090px) and         (max-width: 1090px){
  .Scenario__body .Scenario__image-caption p{
    font-size: 1.8em;
  }
}
@media print{
  .Scenario__body .Scenario__image{
    content: '';
    display: table;
    clear: both;
    border: 0;
    margin-bottom: 5rem;
  }
  .Scenario__body .Scenario__image--illustration{
    width: 100%;
  }
  .Scenario__body .Scenario__image--photo img{
    max-width: 50%;
    margin-top: 0;
  }
  .Scenario__body .Scenario__image--photo figcaption{
    box-sizing: border-box;
    width: 50%;
    padding-top: 0;
    padding-left: 2em;
  }
  .Scenario__body .Scenario__image--photo img,
      .Scenario__body .Scenario__image--photo figcaption{
    float: left;
  }
  .Scenario__body .Scenario__image--photo figcaption,
      .Scenario__body .Scenario__image--photo p{
    margin: 0;
  }
}
@media screen{
  .Scenario__body .Scenario__slideshow{
    clear: both;
    width: 100vw;
    position: relative;
    z-index: 2;
    margin: 2em -1rem !important;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding-bottom: 2rem;
  }
}
@media    (min-width: 1461px){
  .Scenario__body .Scenario__slideshow{
    width: 1460px;
  }
}
@media         (max-width: 1090px){
  .Scenario__body .Scenario__image-caption{
    padding-top: 1em;
  }
}
@media screen and         (min-width: 1091px){
  .Scenario__body > div{
    width: calc(99.9% * 2/4 - (5rem - 5rem * 2/4));
    margin-left: calc(99.9% * (-1/4 * -1) - (5rem - 5rem * (-1/4 * -1)) + 5rem) !important;
    position: relative;
  }
  .Scenario__body > div:nth-child(1n){
    float: left;
    margin-right: 5rem;
    clear: none;
  }
  .Scenario__body > div:last-child{
    margin-right: 0;
  }
  .Scenario__body > div:nth-child(4n){
    margin-right: 0;
    float: right;
  }
  .Scenario__body > div:nth-child(4n + 1){
    clear: both;
  }
  .Scenario__body > div:after,
      .Scenario__body > div:before{
    content: '';
    width: 2px;
    background-image: url('./styles/border-vertical.png');
    background-repeat: repeat-y;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .Scenario__body > div:before{
    left: -2.5rem;
  }
  .Scenario__body > div:after{
    right: -2.5rem;
  }
  .Scenario__body .Scenario__image{
    width: calc(99.9% * 4/4 - (48px - 48px * 4/4));
  }
  .Scenario__body .Scenario__image:nth-child(1n){
    float: left;
    margin-right: 48px;
    clear: none;
  }
  .Scenario__body .Scenario__image:last-child{
    margin-right: 0;
  }
  .Scenario__body .Scenario__image:nth-child(4n){
    margin-right: 0;
    float: right;
  }
  .Scenario__body .Scenario__image:nth-child(4n + 1){
    clear: both;
  }
  .Scenario__body .Scenario__image:nth-child(4n){
    float: left;
  }
  .Scenario__body .Scenario__image{

    /* override Lost cycle */
  }
  .Scenario__body .Scenario__image-caption{
    box-sizing: border-box;
    width: 33.33%;
    position: absolute;
    top: 2rem;
    left: calc(100% + 2.5rem);
    padding-left: 2.5rem;
  }
  .Scenario__body .Scenario__slideshow{
    left: calc(-49.95% - 7.5rem);
  }
}
.NotFound {
  margin-bottom: 3em;
  display: block

}
.NotFound a:hover{
  color: hsl(0, 0%, 65%);
  background-color: transparent;
}
.NotFound__header{
  font-size: 6em;
}
@media         (max-width: 1090px){
  .NotFound__header{
    font-size: 4.8em;
  }
}
@media      (max-width: 500px){
  .NotFound__header{
    font-size: 2.8em;
  }
}
.NotFound__header{
  margin: 0;
  padding: 0;
  line-height: 1;
  display: block;
  margin-bottom: 1em;
}
@media      (min-width: 501px){
  .NotFound__header{
    width: calc(99.9% * 1/2 - (48px - 48px * 1/2));
  }
  .NotFound__header:nth-child(1n){
    float: left;
    margin-right: 48px;
    clear: none;
  }
  .NotFound__header:last-child{
    margin-right: 0;
  }
  .NotFound__header:nth-child(2n){
    margin-right: 0;
    float: right;
  }
  .NotFound__header:nth-child(2n + 1){
    clear: both;
  }
}
.NotFound__image{
  display: block;
  width: 80vw;
}
@media         (min-width: 1091px){
  .NotFound__image{
    width: calc(99.9% * 1/2 - (48px - 48px * 1/2));
  }
  .NotFound__image:nth-child(1n){
    float: left;
    margin-right: 48px;
    clear: none;
  }
  .NotFound__image:last-child{
    margin-right: 0;
  }
  .NotFound__image:nth-child(2n){
    margin-right: 0;
    float: right;
  }
  .NotFound__image:nth-child(2n + 1){
    clear: both;
  }
}
.AboutScenarios__header,
  .AboutScenarios__details{
  margin: 0 0 2rem 0;
  padding: 0;

  /* &:last-child { margin-bottom: 0 } */
}
@media         (min-width: 1091px){
  .AboutScenarios__header,
  .AboutScenarios__details{
    margin-bottom: 5rem;
  }
}
.AboutScenarios__header h2{
  font-size: 6em;
}
@media         (max-width: 1090px){
  .AboutScenarios__header h2{
    font-size: 4.8em;
  }
}
@media      (max-width: 500px){
  .AboutScenarios__header h2{
    font-size: 2.8em;
  }
}
.AboutScenarios__header h2{
  margin: 0;
  padding: 0;
  line-height: 1;
}
.AboutScenarios__sidebar{
  background-color: hsl(115, 100%, 100%);
  padding: 1em;
  margin: 3em 0 3em 0;
}
.AboutScenarios__sidebar h3{
  @apply --display-style;
  font-size: 2em;
}
@media         (max-width: 1090px){
  .AboutScenarios__sidebar h3{
    font-size: 1.8em;
  }
}
.AboutScenarios__sidebar h3{
  margin: 0 0 1em 0;
}
.AboutScenarios__sidebar p{
  @apply --display-style;
  font-size: 2em;
}
@media         (max-width: 1090px){
  .AboutScenarios__sidebar p{
    font-size: 1.8em;
  }
}
.AboutScenarios__sidebar p{
  line-height: 1.2;
}
.AboutScenarios__sidebar p a{
  font-size: 1em;
}
.AboutScenarios__sidebar p a:hover{
  color: black;
}
.AboutScenarios__credits{
  color: blue;
  font-size: 2em;
}
@media         (max-width: 1090px){
  .AboutScenarios__credits{
    font-size: 1.8em;
  }
}
.AboutScenarios__credits:hover,
    .AboutScenarios__credits:active,
    .AboutScenarios__credits:visited{
  color: currentColor;
}
.AboutScenarios__credits,
    .AboutScenarios__credits > *{
  cursor: pointer;
  border: 0;
  border: 0;
  color: currentColor;
  text-decoration: none;
}
.AboutScenarios__credits{
  box-sizing: border-box;
  padding: 0.55em;
  font-weight: 600;
  line-height: 1;
}
.AboutScenarios__credits em{
  font-family: 'Calibre', 'Arial', sans-serif;
  line-height: 1.2;
  font-weight: 600;
  font-style: normal;
}
.AboutScenarios__details h2{
  font-size: 2.8em;
}
@media         (max-width: 1090px){
  .AboutScenarios__details h2{
    font-size: 2em;
  }
}
.AboutScenarios__details h2{
  margin: 2em 0 .25em 0;
  line-height: 1.2;
}
.AboutScenarios__details p{
  font-size: 1.8em;
}
@media         (max-width: 1090px){
  .AboutScenarios__details p{
    font-size: 1.4em;
  }
}
.AboutScenarios__details p{
  margin-bottom: 1em;
  font-family: 'Tiempos Text', 'Arial', sans-serif;
  line-height: 1.55;
  letter-spacing: 0.01em;
}
.AboutScenarios__details ul{
  margin-bottom: 2.0em;
}
.AboutScenarios__details li{
  @apply --display-style;
  font-size: 2em;
}
@media         (max-width: 1090px){
  .AboutScenarios__details li{
    font-size: 1.8em;
  }
}
.AboutScenarios__details strong{
  font-family: 'Calibre', 'Arial', sans-serif;
  font-size: 1.15em;
  line-height: 1;
  font-weight: 600;
}
.AboutScenarios__rule{
  border: 0px;
  margin-top: 4em;
  display: block;
}
.AboutScenarios__figure{
  margin-top: 1em;
}
.AboutScenarios__diagram{
  margin: 2em 0 2em 0;
}
.AboutScenarios__biglink:hover *{
  color: hsl(115, 100%, 100%);
}
.AboutScenarios__biglink a{
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.AboutScenarios__biglink svg{
  transform: rotate(-90deg);
  color: hsl(0, 0%, 65%);
  float: left;
  background-color: hsl(0, 0%, 0%);
  padding: 0;
  margin: 0 .5em 0 0;
  height: 1.2em;
  width: 1.2em;
}
@media         (min-width: 1091px){
  .AboutScenarios__header,
    .AboutScenarios__details{
    width: calc(99.9% * 1/2 - (48px - 48px * 1/2));
  }
  .AboutScenarios__header:nth-child(1n),
    .AboutScenarios__details:nth-child(1n){
    float: left;
    margin-right: 48px;
    clear: none;
  }
  .AboutScenarios__header:last-child,
    .AboutScenarios__details:last-child{
    margin-right: 0;
  }
  .AboutScenarios__header:nth-child(2n),
    .AboutScenarios__details:nth-child(2n){
    margin-right: 0;
    float: right;
  }
  .AboutScenarios__header:nth-child(2n + 1),
    .AboutScenarios__details:nth-child(2n + 1){
    clear: both;
  }
  .AboutScenarios__header .Panel{
    width: 50%;
  }
  .AboutScenarios__header h2{
    margin-bottom: 3em;
  }
  .AboutScenarios__sidebar{
    padding: 1em;
    width: 80%;
  }
}
html {
  font-size:                    62.5%; /* reset root em unit to 10px */
  line-height:                  1.55;
  -webkit-font-smoothing:       subpixel-antialiased;
}
body {
  margin: 0;
  padding: 0;
  background-color: hsl(0, 0%, 0%);
  color: hsl(0, 0%, 0%);
}
p {
  font-size: 1.8em;
}
@media         (max-width: 1090px){
  p{
    font-size: 1.4em;
  }
}
p{
  margin-bottom: 1em;
  font-family: 'Tiempos Text', 'Arial', sans-serif;
  line-height: 1.55;
  letter-spacing: 0.01em;
}
#svg {
  position: absolute;
  top: -999em;
  left: -999em}
